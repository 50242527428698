<template>
    <div>

	<div class="story" v-if="list.length>0">
       <div class="list" v-for="(item,index) in list" :key="index" @click="handlePush(item.id)">
            <div class="left">
				<!-- <img :src="item.img" > -->
				<div class="center">
					<div class="title">
						{{item.title}}
					</div>
					<div class="content" style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
						{{item.remark}}
					</div>
				</div>
            </div>
            <div class="right">
				<div class="time">
				{{item.create_time.split('-')[1]+'.'+item.create_time.split(' ')[0].split('-')[2]}}
				</div>
				<div class="year">
              {{item.create_time.split('-')[0]}}
				</div>
            </div>
       </div>
    </div>
	<No v-if="list.length==0" />
	</div>
</template>

<script>
import No from './no.vue'
export default {
	props:{
		list:Array,
		id:Number
	},
    data() {
        return {

        };
    },
    components:{
		No
    },
    methods: {
			handlePush(id){
			this.$router.push({
				path:'/detail',
				query:{
					id:this.id,
					detailId:id
				}
			})
		}
    }
};
</script>

<style scoped lang='less'>
.list{
	width: 100%;
	/* background-color: red; */
	display: flex;
	height: 125px;
	padding: 20px 0 20px 20px;
    background: #f2f2f2;
    margin: 30px 0;
	cursor: pointer;
	// border-bottom: 1px solid #e4e4e4;
	.left{
		display: flex;
		width: calc(100% - 200px);
		// img{
		// 	width:200px;
		// 	height: 150px;
		// 	border-radius: 10px;
		// 	margin-right: 50px;
		// }
		.center{
			width: calc(100% - 50px);
			margin-right: 50px;
			padding:0 0 20px 0;
			color:#333;
			.title{

				font-size: 16px;
				}
				.content{

					font-size: 13px;
					margin-top: 10px;
					line-height: 2em;
			   }
		}
	}
	.right{
		width: 200px;
		height: 100%;
		/* background-color: yellow; */
		color:#ccc;
		display: flex;
		flex-direction: column;
		padding-left: 5%;
		justify-content: center;
		border-left: 1px solid #e4e4e4;
		.time{
			font-size: 36px;
			font-weight: 600;
		}
		.year{
			font-size: 20px;
			font-weight: 500;
		}
	}
}
.list:hover{
     background: rgba(129, 168, 73, 1);
    //  color: #f2f2f2;
    .left{
        .center{
             color: #f2f2f2;
        }
    }
    .right{
       color: #f2f2f2;
    }
}
</style>
