<template>
  <div class="culture">
    <Top v-if="columnList.img_info_list" :img="'/gsmm/api/v1/'+columnList.img_info_list[0].url" :ch="ch"/>

    <Title :ch="ch" :en="en"/>
    <div class="content">
      {{ columnList.description }}
    </div>
    <div class="center">
      <Background :style="{'width':'calc(100% + 20px)','margin': '10px 0 -15px -10px'}"/>
      <List1 :list="informationList" :id="20"/>
      <Pagination :page="form.pageNum" :pageSize="form.pageSize" :total="informationTotal" @handleList="handleList"/>

    </div>

  </div>
</template>

<script>
import Top from '../components/top.vue'
import List1 from '../components/list1.vue'
import Pagination from '../components/pagination.vue'
import Title from '../components/title.vue'
import Background from '../components/background.vue'
import {mapActions, mapState} from 'vuex'

export default {
  data() {
    return {
      ch: '行业动态',
      en: 'INDUSTRY NEWS',
      form: {
        type: 'DYNAMIC',
        pageNum: 1,
        pageSize: 6
      }
    };
  },
  components: {
    Top,
    List1,
    Title,
    Pagination,
    Background
  },
  computed: {
    ...mapState(['columnList', 'informationList', 'informationTotal'])
  },
  created() {

  },
  mounted() {
    this.handleColumn(20)
    this.handelinformation1(this.form)
  },
  methods: {
    ...mapActions(['handleColumn', 'handelinformation1']),
    handleList(e) {
      this.form.pageNum = e
      this.handelinformation1(this.form)
    }
  }
};
</script>

<style scoped lang='less'>
.culture {
  width: 100%;
  background-image: url('../../assets/bg.png');
  padding-bottom: 50px;
}

.content {
  padding: 20px 30%;
}

.center {
  padding: 0 20%;

  img {
    width: 100%;
    height: 21px;
    margin: 40px 0;
  }

}
</style>
